.vote_immit {
  width: 100%;
  height: 1.733333rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}

.button_style {
  background-color: transparent;
  background-color: #2b5837;
  color: white;
  font-size: 0.4rem;
  width: 5.986667rem;
  height: 0.946667rem;
  border-radius: 0.466667rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}