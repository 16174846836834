.chart_exhibition {
  width: 50%;
  padding: 0.6rem 0.2rem 0;
  box-sizing: border-box;
}

.chart_innner {
  width: 100%;
  height: 5.6rem;
  display: -ms-flexbox;
  display: flex;
  border-radius: 0.133333rem;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 0.026667rem dashed #2b5837;
}

.chart_innner_content {
  width: 100%;
  height: 2.933333rem;
  margin-bottom: 0.133333rem;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.chart_innner_content img {
  position: relative;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 100%;
  border-radius: 0.133333rem;
}

.chart_innner_mask {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.8rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: -ms-flexbox;
  display: flex;
}

.chart_innner_mask_list {
  -ms-flex: 1 1;
  flex: 1 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  opacity: 0.7;
  font-size: 0.266667rem;
  box-sizing: border-box;
}

.chart_innner_mask_list span {
  margin-left: 0.293333rem;
}

.chart_name,
.chart_author {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.373333rem;
  height: 0.746667rem;
}

.chart_name {
  line-height: 0.666667rem;
}

.chart_author {
  line-height: 0.746667rem;
}

.chart_oper {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}

.chart_oper_button {
  width: 2rem;
  height: 0.666667rem;
  box-sizing: border-box;
  font-size: 0.32rem;
}

.chart_oper_button_submit {
  width: 2rem;
  height: 0.666667rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #2b5837;
  border-radius: 0.266667rem;
  color: #fff;
}

.chart_oper_button_submit.none {
  background-color: #b2b2b2;
}