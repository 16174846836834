.menu_chart {
  width: 100%;
  padding: 0 0.2rem;
  box-sizing: border-box;
}

.menu_title {
  width: 100%;
  height: 1.413333rem;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.373333rem;
  color: #676767;
}

.menu_span_left {
  width: 1.866667rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1.466667rem;
}

.menu_span {
  -ms-flex: 1 1;
  flex: 1 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1.466667rem;
}