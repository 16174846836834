.votePreview {
  width: 100%;
}

.votePreview_top {
  width: 100;
  padding-left: 1.28rem;
  box-sizing: border-box;
  height: 2.76rem;
}

.votePreview_top_innner {
  width: 100%;
  height: 100%;
  position: relative;
}

.Head_box {
  width: 1.866667rem;
  height: 1.866667rem;
  position: absolute;
  top: 0;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 100%;
  z-index: 2;
  background-color: #fff;
}

.Head {
  width: 1.626667rem;
  height: 1.626667rem;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.HeadTopImg,
.vote_info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.826667rem;
  z-index: 1;
}

.vote_info {
  z-index: 2;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.32rem;
  color: #fff;
}

.vote_info_list {
  -ms-flex: 1 1;
  flex: 1 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}

.votePreview_content {
  padding: 1.28rem 1.28rem 0.8rem;
}

.votePreview_content_imgs {
  width: 100%;
  height: 5.333333rem;
  border-radius: 0.133333rem;
  position: relative;
}

.BgcImg {
  width: 100%;
  height: 100%;
}

.demoImg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0.32rem;
  box-sizing: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.demoImg_img {
  height: 100%;
  position: relative;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.votePreview_decri {
  width: 100%;
  padding-top: 0.8rem;
}

.votePreview_decri h3 {
  font-size: 0.48rem;
  text-align: center;
  height: 1.013333rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
}

.votePreview_victor {
  font-size: 0.346667rem;
  line-height: 0.533333rem;
}

.votePreview_victor_name {
  font-size: 0.346667rem;
  height: 0.8rem;
  text-align: center;
}