.menu_chart_list {
  width: 100%;
  border-bottom: 0.013333rem solid rgba(214, 221, 246, 0.8);
  display: -ms-flexbox;
  display: flex;
}

.menu_chart_left,
.menu_chart_right {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 0.373333rem;
  color: #333333;
}

.menu_chart_left {
  width: 1.866667rem;
}

.menu_chart_right {
  -ms-flex: 1 1;
  flex: 1 1;
}

.menu_chart_right.two {
  color: #2d7697;
}

.brand_image {
  width: 0.973333rem;
  height: 0.946667rem;
}