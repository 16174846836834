.vote_center {
  width: 100%;
  height: 2.666667rem;
  box-sizing: border-box;
  border: 0.226667rem solid #2b5837;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.vote_center_item {
  -ms-flex: 1 1;
  flex: 1 1;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.533333rem;
}

.style_name {
  font-size: 0.32rem;
}

.line {
  width: 0.026667rem;
  height: 0.546667rem;
  background-color: #cfdde4;
}