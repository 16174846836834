.vote_list {
  width: 100%;
}

.input_item {
  width: 100%;
  margin-top: 0.266667rem;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(43, 88, 55, 0.1);
  border-radius: 0.133333rem;
}

.vote_search {
  width: 0.533333rem;
  height: 1.093333rem;
  display: -ms-flexbox;
  display: flex;
  padding-right: 0.2rem;
  -ms-flex-align: center;
  align-items: center;
}

.vote_search img {
  width: 0.426667rem;
  height: 0.426667rem;
}

.input_change {
  -ms-flex: 1 1;
  flex: 1 1;
  overflow: hidden;
}

:global .am-list-item {
  min-height: 0.933333rem;
  background-color: transparent;
}

:global .am-list-line {
  height: 0.933333rem;
}

:global .am-list-item.am-input-item.am-list-item-middle {
  height: 0.933333rem;
}