.vote_charts {
  width: 100%;
  height: 1.066667rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  box-sizing: border-box;
  border-bottom: 0.013333rem solid rgba(45, 118, 151, 0.2);
}

.vote_link_item {
  width: 1.6rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: PingFangSC-Bold;
  font-size: 0.4rem;
  font-weight: 400;
}

.work_content {
  width: 100%;
  height: 1.333333rem;
}

.vote_link {
  color: red;
}

:global .am-tabs-default-bar-tab-active {
  color: #2b5837;
}

:global .am-tabs-default-bar-underline {
  border-color: #2b5837;
}