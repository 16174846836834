.driver {
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  padding: 0 0.693333rem;
  box-sizing: border-box;
}

.input_item {
  height: 1.066667rem;
  background-color: red;
}

.button {
  width: 100%;
  padding-top: 1.066667rem;
}

.button .button_confirm {
  width: 100%;
  border-radius: 0.133333rem;
  font-size: 0.373333rem;
}

.tips {
  line-height: 1.066667rem;
  font-size: 0.186667rem;
  color: #bbbbbb;
}

.location {
  height: 1.066667rem;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0.4rem;
  box-sizing: border-box;
  -ms-flex-pack: left;
  justify-content: left;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.48rem;
}

:global .am-list-header {
  font-size: 0.4rem;
  color: #333;
}

:global .am-list-item.am-input-item.am-list-item-middle {
  height: 1.12rem;
}

:global .am-list-line {
  height: 1.12rem;
}

:global .am-list-line .am-input-control input::-webkit-input-placeholder {
  color: #999999;
}

.button :global(.am-button-primary) {
  background-color: #2b5837;
}