.form_content {
  width: 100%;
  padding: 0 0.4rem;
  box-sizing: border-box;
  padding-top: 0.2rem;
}

.input_style {
  width: 100%;
  height: 0.933333rem;
  background-color: rgba(43, 88, 55, 0.1);
  border-radius: 0.133333rem;
  margin-top: 0.533333rem;
  font-size: 0.346667rem;
}

.demoTitle:before,
.demoTitle:after {
  border-bottom: none;
}

.input_style_area {
  width: 100%;
  height: 1.866667rem;
  background-color: rgba(43, 88, 55, 0.1);
  border-radius: 0.133333rem;
  margin-top: 0.533333rem;
}

.tips {
  width: 100%;
  font-size: 0.266667rem;
  color: #979797;
}

.input_style :global(.am-list-line .am-input-control input::-webkit-input-placeholder) {
  color: #2b5837;
}

:global .am-textarea-control textarea {
  height: 1.866667rem;
  width: 100%;
  font-size: 0.346667rem;
  padding-left: 0.2rem;
  background-color: rgba(43, 88, 55, 0.1);
  border-radius: 0.133333rem;
}

:global .am-textarea-control textarea::-webkit-input-placeholder {
  color: #2b5837;
  padding-top: 0.133333rem;
}

:global .am-list-line .am-input-control input {
  background-color: none;
  padding-left: 0.2rem;
  font-size: 0.346667rem;
}

:global .am-list-item.am-input-item {
  padding-left: 0;
}

:global .am-list .am-list-item.am-textarea-item {
  padding: 0;
}

:global .am-image-picker-item.am-image-picker-upload-btn {
  background-color: rgba(43, 88, 55, 0.1);
}