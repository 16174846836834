.result {
  width: 100%;
  height: 100vh;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.result .result_logo {
  width: 100%;
  padding-top: 0.346667rem;
  padding-left: 0.4rem;
  box-sizing: border-box;
}

.result .result_logo img {
  height: 0.333333rem;
  width: 3.16rem;
}

.result .result_content {
  width: 84%;
  height: 70%;
  border-radius: 0.4rem;
  background-color: #fff;
  position: relative;
  left: 50%;
  top: 1.333333rem;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.result .result_content .card_img {
  width: 1.733333rem;
  height: 1.733333rem;
  border-radius: 100%;
  position: absolute;
  top: -0.4rem;
  right: -0.266667rem;
}

.result .result_content .result_img {
  position: absolute;
  top: 0;
  left: 50%;
  width: 3.866667rem;
  height: 1.013333rem;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.result .result_content .result_img1 {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: auto;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.result .result_content .result_img2 {
  position: absolute;
  bottom: -0.133333rem;
  left: 50%;
  width: 100%;
  height: auto;
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.result .result_content .result_inner {
  width: 100%;
  height: 100%;
  padding: 0.8rem;
  position: relative;
  box-sizing: border-box;
}

.result .result_content .result_inner .result_mask {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0.4rem;
  background-color: rgba(0, 0, 0, 0.2);
}

.result .result_content .result_inner .result_inner_title {
  width: 100%;
  height: 1.866667rem;
  font-size: 0.533333rem;
}

.result .result_content .result_inner .result_inner_title p {
  line-height: 0.8rem;
  color: #bf5757;
  text-align: center;
}

.result .result_content .result_inner .result_inner_img {
  width: 100%;
  position: relative;
}

.result .result_content .result_inner .result_inner_img img {
  position: relative;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 4.08rem;
  top: 0.533333rem;
  height: auto;
  margin-bottom: 1.066667rem;
}

.result .result_content .result_inner .result_inner_img p {
  font-size: 0.266667rem;
  box-sizing: border-box;
  color: #666666;
  line-height: 0.533333rem;
}