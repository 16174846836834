.chart_exhibition {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 0.2rem;
}

.pagination_container {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
}

:global .am-flexbox {
  overflow: visible;
}

:global .am-flexbox.am-flexbox-item {
  min-width: none;
}