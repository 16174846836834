.work_rule {
  width: 100%;
  padding: 0.666667rem 0.4rem;
}

.work_rule_title {
  width: 100%;
  height: 0.826667rem;
  background-color: #2b5837;
  font-size: 0.373333rem;
  color: #fff;
  padding-left: 0.4rem;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.work_rule_content {
  font-size: 0.346667rem;
  padding-left: 0.4rem;
  box-sizing: border-box;
  padding-top: 0.133333rem;
  line-height: 0.666667rem;
  padding-bottom: 0.4rem;
}