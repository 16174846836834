/* pages/collect_money/collect_money.wxss */

/* pages/payment-activities/payment-activities.wxss */

.inner_ac {
  width: 100%;
  background-color: #f7f7f7;
}

.payment_activities {
  width: 100%;
  padding-bottom: 2.133333rem;
  box-sizing: border-box;
  overflow-y: scroll;
}

.payment_activities_button {
  width: 100%;
  height: 2.133333rem;
  background-color: #f7f7f7;
  padding: 0 0.8rem;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.payment_activities_nav {
  font-size: 0.373333rem;
  width: 100%;
  height: 1.066667rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #1c64bc;
  border-radius: 0.133333rem;
  color: #ffffff;
}

.none_image {
  position: relative;
  top: 2.666667rem;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 4rem;
  /* padding-top: 60px; */
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
}

/* pages/agreement/agreement.wxss */

.agreement {
  width: 100%;
  background-color: #f7f7f7;
  padding: 0 0.666667rem 0.8rem;
  box-sizing: border-box;
}

.agreement_title {
  width: 100%;
  padding: 0.8rem 0 0.4rem;
  box-sizing: border-box;
  /* height: 120px; */
  /* line-height: 120px; */
  font-size: 0.426667rem;
  font-weight: 900;
  text-align: center;
}

.agreement_content_item {
  width: 100%;
  text-indent: 2em;
  line-height: 0.8rem;
  font-size: 0.346667rem;
}

.agreement_content_title {
  width: 100%;
  height: 1.333333rem;
  line-height: 1.333333rem;
  font-size: 0.426667rem;
  font-weight: 900;
}