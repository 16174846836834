.toast_content {
  width: 100%;
  padding: 0.8rem;
  box-sizing: border-box;
}

.toast_content h3 {
  font-size: 0.4rem;
  font-weight: 700;
  padding: 0 0 0.8rem;
  line-height: 0.666667rem;
  box-sizing: border-box;
}

.toast_content p {
  line-height: 0.666667rem;
  font-size: 0.266667rem;
}