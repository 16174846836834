.wheelContainer {
  width: 100%;
  height: 100vh;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.wheelContainer .top {
  width: 100%;
  height: 4.8rem;
  padding-top: 1.066667rem;
  box-sizing: border-box;
}

.wheelContainer .top .titleimg {
  width: 90%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.wheelContainer .wheelMain {
  text-align: center;
  position: relative;
  background-size: auto 100%;
  background-position: center;
  margin: 0 auto;
  width: 100%;
}

.wheelContainer .wheelMain .wheelButton_mount {
  width: 2.866667rem;
  height: 1.506667rem;
  position: absolute;
  z-index: 0;
  bottom: 0.266667rem;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.wheelContainer .wheelMain .wheelButton_people {
  position: absolute;
  bottom: -0.48rem;
  left: 50%;
  width: 94%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 3;
}

.wheelContainer .wheelMain .wheel {
  margin: 0 auto;
  width: 96%;
  position: relative;
  z-index: 2;
}

.wheelContainer .wheelMain .wheel .wheelCircle {
  position: relative;
}

.wheelContainer .wheelMain .wheel .item {
  position: absolute;
  top: 11.5%;
  width: 76%;
  right: 12%;
}

.wheelContainer .wheelMain .wheel .pointer {
  position: absolute;
  width: 31%;
  height: 31%;
  left: 34.7%;
  top: 30.3%;
  cursor: pointer;
}

.wheelContainer .wheelButton {
  width: 100%;
  -ms-flex: 1 1;
  flex: 1 1;
  background-color: #cc6464;
  position: relative;
}

.wheelContainer .wheelButton .rule {
  position: absolute;
  width: 50%;
  left: 50%;
  top: 0.8rem;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.wheelContainer .wheelButton .names_turn {
  position: absolute;
  bottom: 0.666667rem;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 5.733333rem;
  height: 0.8rem;
  line-height: 0.693333rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  font-size: 0.213333rem;
  color: #fff;
  padding-left: 0.266667rem;
  box-sizing: border-box;
}

.wheelContainer img {
  max-width: 100%;
}

@media screen and (max-width: 375px) {
  .wheel-container .wheel-main .wheel {
    width: 4rem;
  }
}

@media screen and (max-width: 320px) {
  .wheel-container .wheel-main .wheel {
    width: 3.733333rem;
  }
}

@media screen and (max-width: 414px) {
  .wheel-container .wheel-main .wheel {
    width: 4rem;
  }
}